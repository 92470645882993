@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap);
.login-logo-page figure{display:block !important;margin:0 auto !important}.login-page-container{min-width:400px}@media (max-width: 400px){.login-page-container{min-width:280px}}

.react-time-picker {
  width: 100%;
}
.react-time-picker__wrapper {
  padding: 5px;
  border-radius: 5px;
}
.react-time-picker__inputGroup__input:invalid {
  background: rgb(255 255 255);
}

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1 1;
  /* The phone number input should shrink
       to make room for the extension input */
  min-width: 0;
  height: 33px !important;
  border: 1px solid #cfcfcf !important;
  background: #fbfcfd !important;
  padding-left: 5px !important;
}

.PhoneInputCountryIcon {
  width: calc(
    1em *
      1.5);
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: 1em;
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: 1em;
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
       it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
       and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
       and sometime there can be seen white pixels of the background at top and bottom,
       so an additional "inset" border is added. */
  box-shadow: 0 0 0 1px
      rgba(0, 0, 0, 0.5),
    inset 0 0 0 1px
      rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
       Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: 0.8;
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: 0.65;
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  padding-left: 3px !important;
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 0.3em;
  width: var(--PhoneInputCountrySelectArrow-width);
  height: 0.3em;
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: 0.35em;
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: inherit;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: 1px;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: 1px;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: rotate(45deg);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: 0.45;
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 1px
      #03b2cb,
    inset 0 0 0 1px
      #03b2cb;
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.mobileNumberContainerClass {
  background-color: #fbfcfd !important;
}

.mobileNumberContainerClass:focus {
  background-color: #ffff !important;
  border-bottom: 2px solid blue !important;
}

@media only screen and (max-width: 1300px) {
  .user-main {
    flex-direction: column;
  }
}

html,body,*{font-family:"Inter", sans-serif}.orders-summary-container{height:calc(100vh - 191px);overflow:auto}.order-custom-toast .euiToast--primary{border-top:4px solid #275af6 !important}.order-custom-toast .euiToast{background-color:#fcfcfc}.order-custom-toast .euiToastHeader{display:none}.flex-horizontal-center{justify-content:center;-webkit-justify-content:center}.flex-vertical-center{align-content:center;-webkit-align-content:center}.orders-summary-card{cursor:pointer}.orders-summary-card .euiToastHeader{display:none !important}.order-details-card{height:calc(100vh - 349px);overflow:auto;overflow-x:hidden}.orders-summary-container-past{height:calc(100vh - 196px);overflow:auto}.order-details-card-past{height:calc(100vh - 155px);overflow:auto}.header-logo-img img{width:110px;height:auto;margin-top:10px}.mr-left-10{margin-left:10px}.mr-left-20{margin-left:20px}.main-page-height{height:calc(100vh - 49px) !important;overflow:auto}.no-shadow{box-shadow:none !important;-moz-box-shadow:none !important;-o-box-shadow:none !important;-webkit-box-shadow:none !important}.no-order-image{width:70px;display:block;margin-left:auto;margin-right:auto}.cancel-order-button{background-color:#ff0000 !important;border-color:#ff0000 !important}.mr-top-none{margin-top:0 !important}.custom-badge{padding:6px 12px !important}.text-uppercase{text-transform:uppercase}.text-justify{text-align:justify;text-justify:distribute}.border-radius-4{border-radius:4px;-moz-border-radius:4px;-webkit-border-radius:4px}.no-order-section{position:relative;height:70vh}.no-order-section-child{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.no-border{border:none !important}.calendar-icon .euiFormControlLayoutIcons{left:18px !important}.full-screen-flyout{width:100vw !important}.agmark-img{width:18px;margin-top:5px;margin-left:5px}


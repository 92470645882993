.login-logo-page figure {
  display: block !important;
  margin: 0 auto !important;
}
.login-page-container {
  min-width: 400px;
}
@media (max-width: 400px) {
  .login-page-container {
    min-width: 280px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

html,
body,
* {
  font-family: "Inter", sans-serif;
}

.orders-summary-container {
  height: calc(100vh - 191px);
  overflow: auto;
}
.order-custom-toast {
  .euiToast--primary {
    border-top: 4px solid #275af6 !important;
  }
  .euiToast {
    background-color: #fcfcfc;
  }
  .euiToastHeader {
    display: none;
  }
}
.flex-horizontal-center {
  justify-content: center;
  -webkit-justify-content: center;
}
.flex-vertical-center {
  align-content: center;
  -webkit-align-content: center;
}
.orders-summary-card {
  cursor: pointer;
  .euiToastHeader {
    display: none !important;
  }
}
.order-details-card {
  height: calc(100vh - 349px);
  overflow: auto;
  overflow-x: hidden;
}
.orders-summary-container-past {
  height: calc(100vh - 196px);
  overflow: auto;
}
.order-details-card-past {
  height: calc(100vh - 155px);
  overflow: auto;
}
.header-logo-img img {
  width: 110px;
  height: auto;
  margin-top: 10px;
}
.mr-left-10 {
  margin-left: 10px;
}
.mr-left-20 {
  margin-left: 20px;
}
.main-page-height {
  height: calc(100vh - 49px) !important;
  overflow: auto;
}

.no-shadow {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.no-order-image {
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cancel-order-button {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}
.mr-top-none {
  margin-top: 0 !important;
}
.custom-badge {
  padding: 6px 12px !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-justify {
  text-align: justify;
  text-justify: distribute;
}
.border-radius-4 {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.no-order-section {
  position: relative;
  height: 70vh;
}
.no-order-section-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-border {
  border: none !important;
}
.calendar-icon {
  .euiFormControlLayoutIcons {
    left: 18px !important;
  }
}
.full-screen-flyout {
  width: 100vw !important;
}
.agmark-img {
  width: 18px;
  margin-top: 5px;
  margin-left: 5px;
}
